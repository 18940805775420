import { defineStore } from 'pinia';
import moment from 'moment';
import { mapArrayToObjectsByKey } from '@/helpers/functions';
import { landerAnalyticsChart } from '@/helpers/chart';
import { useLandingPagesStore } from './landingPages';

const FORMAT = 'YYYY-MM-DD';
// const DATE_RANGE = { startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT), endDate: moment().endOf('day').format(FORMAT) };
const DATE_RANGE = { startDate: moment().startOf('day').format(FORMAT), endDate: moment().endOf('day').format(FORMAT) };

export const useReportingStore = defineStore('reporting', {
  state: () => ({
    dateRange: DATE_RANGE,
    lastUpdated: new Date().toISOString(),
    showGraph: true,
    stats: {
      visits: {
        title: 'Visits',
        icon: 'pageViewsStats',
        value: 0,
        comparision: 0,
      },
      // uniqueVisits: {
      //   title: 'Visitors',
      //   icon: 'visitorsStats',
      //   value: 0,
      //   comparision: 0,
      // },
      clicks: {
        title: 'Clicks',
        icon: 'clicksStats',
        value: 0,
        comparision: 0,
      },
      leads: {
        title: 'Leads',
        icon: 'leadsStats',
        value: 0,
        comparision: 0,
      },
      conversions: {
        title: 'Conversions',
        icon: 'conversionsStats',
        value: 0,
        comparision: 0,
      },
    },
    performance: {
      compareLanders: [],
      chartMetric: 'visits',
      chartType: 'line',
      stats: [],
      analytics: [],
    },
    reports: {},
    loaded: false,
  }),
  getters: {
    analyticsById: (state) => mapArrayToObjectsByKey(state.performance.analytics, 'id'),
    landersWithAnalytics(state) {
      const landingPagesStore = useLandingPagesStore();
      if (state.loaded && landingPagesStore.loaded) {
        return landingPagesStore.landers.filter((item) => !item.websiteId).map((item) => ({ ...item, analytics: state.analyticsById[item.id] }));
      }
      return [];
    },
    reportingChart: (state) => {
      const chart = landerAnalyticsChart(state.performance.chartType);
      const categories = state.performance.stats.length ? state.performance.stats[0].stats.map((item) => item.date) : [];
      return { ...chart, xaxis: { ...chart.xaxis, categories } };
    },
  },
  actions: {
    addPerformanceAnalytics(payload) {
      this.performance.analytics.push(payload);
    },
    clearStore() {
      this.$reset();
    },
  },
});
