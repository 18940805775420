// Default Lander Analytics Stats
const defaultLanderAnalyticsStats = () => ({
  visits: {},
  // uniqueVisits: {},
  clicks: {},
  leads: {},
  conversions: {},
});

// Default Lander Analytics Reports
const defaultLanderAnalyticsReports = () => ({
  deviceType: {
    title: 'Device Type',
    items: {},
  },
  connectionReferrerDomain: {
    title: 'Referrer',
    items: {},
  },
  locationCountry: {
    title: 'Countries',
    items: {},
  },
  locationCity: {
    title: 'Cities',
    items: {},
  },
});

// Default Lander Settings
const defaultLanderSettings = () => ({
  general: {
    title: '',
    language: 'en',
    favicon: '',
  },
  seo: {
    keywords: '',
    description: '',
  },
  customCode: {
    head: '',
    body: '',
  },
  tracking: {
    facebookPixel: '',
    googleTagManager: '',
    googleAnalytics: '',
    ringbaCampaignId: '',
    retreaverCampaignKey: ''
  },
  dynamicTokens: {
    tokens: false,
  },
  conversions: {
    visits: false,
    forms: false,
    links: false
  },
  fonts: {
    fonts: '',
  },
});

// Lander Published URL
const landerUrl = ({ domain, path, indexFile }) => {
  domain = domain.replace(/^https?:\/\//, '');
  path = path === '/' ? '' : path;
  indexFile = !indexFile || indexFile === 'index.html' ? '' : `/${indexFile}`;

  return `${domain}${path}${indexFile}`;
};

// Map Lander
const mapLander = ((lander) => {
  const { masterVariant: { image, indexFile }, domain, path } = lander;

  return {
    ...lander,
    type: 'lander',
    image,
    publishedUrl: lander.published ? landerUrl({ domain: domain.name, path, indexFile }) : '',
  };
});

export {
  defaultLanderAnalyticsStats,
  defaultLanderAnalyticsReports,
  defaultLanderSettings,
  landerUrl,
  mapLander
};
